body {
  padding: 0;
  margin: 0;
}

.uppy-Root {
  position: absolute;
  height: 100%;
  width: 100%;
  display: block;
  padding: 0;
  margin: 0;
}

.uppy-size--md .uppy-Dashboard-inner {
  margin: 0 auto;
  padding: 0;
  width: 100% !important;
  height: 100% !important;
}
